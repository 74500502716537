@import 'stream-chat-angular/src/assets/styles/scss/index.scss';

#chat-root {
  font-family: "stream-chat-icons", serif !important;
  display: flex;
  height: 100%;
 max-height:600px;
  min-height:400px;
  .menu-open {
    width: 100%;
    height: 100%;
    max-height:600px;
    min-height:400px;

    z-index: 1;
  }

  .menu-close {
    width: 0;
  }

  .menu-button {
    display: block;
  }

  .channel {
    min-width: 0;
    width: 100%;
  }

  .thread {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
  }


    .channel{
    &.menu-open{
      display:none;
    }
    .channel-list.thread-open {
      &.menu-open {
        width: 30%;
        height: 100%;
        position: fixed;
        z-index: 1;
      }

      &.menu-close {
        width: 0;
      }

      & + .channel .menu-button {
        display: block;
      }
    }

    .channel-list.thread-close {
      width: 30%;
      max-width: 420px;
      position: initial;
      z-index: auto;

      & + .channel .menu-button {
        display: none;
      }
    }

    .thread {
      width: 55%;
      position: initial;
      z-index: auto;
    }
  }

  @media screen and (min-width: 1024px) {
    .menu-button {
      display: none;
    }

    .channel-list {
      width: 30%;
      max-width: 320px;
      position: initial;
      z-index: auto;
    }

    .thread {
      width: 45%;
      position: initial;
      z-index: auto;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .str-chat__message-options {
    --str-chat__message-options-button-size: 40px;
    svg {
      transform: scale(1.5);
    }
  }
}
